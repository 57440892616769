import { Component, Input, computed, signal } from '@angular/core';
import {
	ButtonSizeClasses,
	ButtonSizes,
	ButtonStyleTypes,
	ButtonTypes,
	StyleClasses,
} from './button.component.data';

@Component({
	selector: 'ui-button',
	templateUrl: './button.component.html',
})
export class ButtonComponent {
	buttonSizes = ButtonSizes;
	buttonStyleTypes = ButtonStyleTypes;
	buttonTypes = ButtonTypes;

	@Input() set styleType(value: ButtonStyleTypes) {
		this.typeSig.set(value);
	}
	typeSig = signal<ButtonStyleTypes>(ButtonStyleTypes.PRIMARY);
	styleClasses = computed(() => {
		return (
			StyleClasses[this.typeSig()] ||
			StyleClasses[ButtonStyleTypes.PRIMARY]
		);
	});

	@Input() set size(value: ButtonSizes) {
		this.sizeSig.set(value);
	}
	sizeSig = signal<ButtonSizes>(this.size);
	sizeClasses = computed<string>(() => {
		return (
			ButtonSizeClasses[this.sizeSig()] ||
			ButtonSizeClasses[ButtonSizes.M]
		);
	});

	@Input() set isSubmit(value: boolean) {
		this.isSubmitButtonSig.set(value);
	}
	isSubmitButtonSig = signal<boolean>(this.isSubmit);
	buttonType = computed<ButtonTypes>(() => {
		return this.isSubmitButtonSig()
			? this.buttonTypes.SUBMIT
			: this.buttonTypes.BUTTON;
	});

	@Input() isDisabled: boolean | null = false;

	@Input() set fullWidth(value: boolean) {
		this.isFullWidthSig.set(value);
	}
	isFullWidthSig = signal<boolean | undefined>(undefined);
}
